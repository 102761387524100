.stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 100px;
    box-shadow: 0 5px 1px rgb(84, 200, 31);
    transition: transform 0.3s ease;
  }
  
  .stat:hover {
    transform: translateY(-10px);
  }
  
  .stat-figure {
    margin-bottom: 20px;
  }
  
  .stat-title {
    
    font-size: 1.2em;
    margin-bottom: 5px;
    color: #555;
  }
  
  .stat-value {
    font-size: 2em;
    font-weight: bold;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .stat-title {
      font-size: 1em;
    }
  
    .stat-value {
      font-size: 1.5em;
    }
  }
  
  @media (max-width: 480px) {
    .stat-title {
      font-size: 1em;
    }
  
    .stat-value {
      font-size: 1.8em;
    }
  }
  