/* Container styling */
.contact-form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem;
  flex-wrap: wrap;
}

/* Contact info styling */
.contact-info,
.contact-form {
  flex: 1;
  min-width: 300px;
}

/* Contact info specific styling */
.contact-info {
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  line-height: 1.2; /* Improve readability */
}

.contact-info p {
  font-size: 1.5rem;
  color: #7CA24A;
  margin-bottom: 2rem;
  line-height: 1.4; /* Improve readability */
}

/* Contact form styling */
.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Form styling for larger screens */
@media (min-width: 768px) {
  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Enhanced spacing between fields */
  }

  .form-group {
    flex: 1;
    min-width: calc(50% - 0.75rem); /* Adjusted for larger gap */
  }

  .form-group input{
    width: 100%;
    padding: 0.75rem;
    border: 1.5px solid #000000;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 1rem;
    transition: border-color 0.3s, box-shadow 0.3s; /* Added transition for box-shadow */
  }
  .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #000000;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 1rem;
    transition: border-color 0.3s, box-shadow 0.3s; /* Added transition for box-shadow */
  }

  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #7CA24A;
    box-shadow: 0 0 5px rgba(124, 174, 0, 0.5); /* Subtle shadow effect */
  }
}

/* Form group styling */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem; /* Enhanced spacing between fields */
}

.form-group input,
.form-group textarea {
  font-size: 1rem;
}

.form-group textarea {
  min-height: 150px;
  max-height: 300px;
  resize: vertical;
}

.submit-button {
  padding: 1rem;
  background-color: #7CA24A;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; /* Added transition for box-shadow */
}

.submit-button:hover {
  background-color: #6a9335;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Subtle shadow effect on hover */
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error {
  color: darkgray;
  font-size: 0.875rem;
  margin-top: 0.25rem; /* Added margin-top for spacing */
}

.success {
  color: #7CA24A;
  font-size: 1rem;
  margin-top: 1rem; /* Added margin-top for spacing */
}

.animate {
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Media Queries for Enhanced Responsiveness */
@media (max-width: 1200px) {
  .contact-info h1 {
    font-size: 1.75rem;
  }
  .contact-info p {
    font-size: 0.95rem;
  }
}

@media (max-width: 992px) {
  .contact-info h1 {
    font-size: 1.5rem;
  }
  .contact-info p {
    font-size: 0.875rem;
  }
}

@media (max-width: 768px) {
  .contact-form-container {
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
  }
  .contact-info,
  .contact-form {
    padding: 0;
    margin-bottom: 1rem;
  }
  .contact-info h1 {
    font-size: 1.25rem;
  }
  .contact-info p {
    font-size: 0.75rem;
  }
  .form {
    flex-direction: column;
    gap: 1rem; /* Enhanced gap for mobile devices */
  }
  .form-group {
    width: 100%;
    margin-bottom: 1.5rem; /* Enhanced spacing between fields */
  }
  .form-group input,
  .form-group textarea {
    font-size: 1rem;
    padding: 0.75rem; /* Enhanced padding for touch targets */
  }
}

@media (max-width: 576px) {
  .contact-info h1 {
    font-size: 2.4rem;
  }
  .contact-info p {
    font-size: 1.3rem;
  }
  .form-group input{
    border: 1.5px solid #000000;

  }
  .form-group textarea {
    font-size: 0.875rem;
    padding: 0.75rem; /* Adjusted padding for better spacing */
    
  }
  .submit-button {
    padding: 0.75rem;
    font-size: 0.875rem;
  }
}
