.trip-advisor-widget-container {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 5px; /* Further reduced padding to fit better on small screens */
  display: flex;
  justify-content: center; /* Center horizontally */
  flex-direction: column; /* Ensure proper layout on small screens */
}

.elfsight-app-e7237de2-d50d-4bce-9b0b-11ca95db146c {
  width: 100%;
  max-width: 100%; /* Ensure it does not exceed container width */
  height: auto; /* Let height adjust automatically */
  overflow: hidden;
}

/* Media Queries for responsiveness */

/* For larger screens and desktops */
@media (min-width: 1024px) {
  .trip-advisor-widget-container {
      padding: 20px; /* More padding for larger screens */
  }
}

/* For tablets (portrait and landscape) */
@media (min-width: 600px) and (max-width: 1024px) {
  .trip-advisor-widget-container {
      padding: 15px; /* Adjust padding for tablets */
  }

  .elfsight-app-e7237de2-d50d-4bce-9b0b-11ca95db146c {
      /* Optional: Adjust any additional styles specific to tablets */
  }
}

/* For mobile devices */
@media (max-width: 599px) {
  .trip-advisor-widget-container {
      padding: 10px; /* Less padding for mobile devices */
  }

  .elfsight-app-e7237de2-d50d-4bce-9b0b-11ca95db146c {
      /* Optional: Adjust any additional styles specific to mobile devices */
  }
}

/* For iPhone SE */
@media (max-width: 375px) {
  .trip-advisor-widget-container {
      padding: 5px; /* Further reduced padding for iPhone SE */
      flex-direction: column; /* Ensure proper layout on small screens */
  }

  .elfsight-app-e7237de2-d50d-4bce-9b0b-11ca95db146c {
      width: 100%; /* Ensure it uses full width */
      max-width: 100%;
  }
}
