/* Custom property define karen */
:root {
  --thm-black-rgb: 49, 48, 65;
}

/* Existing styles */
.carousel-item img {
  background-color: rgb(var(--thm-black-rgb)); /* Custom property ka use karen */
  background-size: cover;
  background-position: center;
  height: 560px; /* Aap apni zarurat ke anusar height set kar sakte hain */
  display: flex;
  align-items: center;
  color: black;
  position: relative; /* Make the image position relative to position the overlay */
}

/* Overlay styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity as needed */
}

/* Centered caption styles */
.centered-caption {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

/* Responsive styles for different screen sizes */
@media (max-width: 768px) {
  .centered-caption h1 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }
  .centered-caption {
    top: 45%;
    left: 30%;
    transform: translate(-50%, -50%);
    align-items: center;
    white-space: nowrap; /* Prevent text from wrapping to the next line */
  }
  

  .centered-caption p {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 576px) {
  .centered-caption h1 {
    font-size: 1.2rem; /* Further adjust font size for very small screens */
  }

  .centered-caption p {
    font-size: 0.9rem; /* Further adjust font size for very small screens */
  }
}


/* New style for the desired text */
.carousel-caption .styled-h1 {
  
  font-family: "Pacifico", cursive;
 
  
  color: #7ca24a; /* Adjust the color to match the text in the image */
  font-size: 48px; /* Adjust the size as needed */
  font-weight: normal; /* Adjust the weight as needed */
  text-align: center; /* Center the text if needed */
}

.carousel-caption .styled-p {
  
  color: #ffffff; /* Adjust the color to match the text in the image */
  font-size: 18px; /* Adjust the size as needed */
  font-weight: bold; /* Adjust the weight as needed */
  text-align: center; /* Center the text if needed */
}
.carousel-caption p {
  animation: bounce 3s ease forwards;
}

.carousel-caption button {
  display: flex;
  font-family: 'Euclid Circular A';
  background-color: #530937;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.carousel-caption button:hover {
  background-color: #ff8000;
  transform: scale(1.1);
}

/* New styles for removing underline from the link */
.no-underline {
  text-decoration: none;
}

@keyframes bounce {
  from {
    opacity: 0;
    transform: translateY(500px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.carousel-item {
  animation: slide-in 1s ;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-200px); /* Slide ko upar se neeche */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Slide karke neeche aaye */
  }
}

@keyframes slideRightToLeft {
  from {
    transform: translateX(200%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) { /* iPhone 6/7/8 */
  .carousel-caption .styled-h1 {
    font-size: 2.6rem;
  }
  .carousel-caption .styled-p {
    font-size: 1rem;
  }
}
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .carousel-caption .styled-h1 {
    font-size: 2.7rem; /* Adjust for iPhone 12 Pro */
  }
  .carousel-caption .styled-p {
    font-size: 1rem; /* Adjust for iPhone 12 Pro */
  }
}
@media only screen and (device-width: 360px) and (device-height: 740px) and (-webkit-device-pixel-ratio: 4) {
  .carousel-caption .styled-h1 {
    font-size: 2.5rem; /* Adjust for Samsung Galaxy S8+ */
  }
  .carousel-caption .styled-p {
    font-size: 0.95rem; /* Adjust for Samsung Galaxy S8+ */
  }
}
/* iPad Mini specific styles */
@media only screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) {
  .carousel-caption .styled-h1 {
    font-size: 5rem; /* Adjust for iPad Mini */
  }
  .carousel-caption .styled-p {
    font-size: 1.8rem; /* Adjust for iPad Mini */
  }
}
/* Samsung Galaxy Z Fold 5 (unfolded state) specific styles */
@media only screen and (min-width: 1536px) and (max-width: 1600px) and (-webkit-device-pixel-ratio: 4) {
  .carousel-caption .styled-h1 {
    font-size: 2rem; /* Adjust for larger unfolded display */
  }
  .carousel-caption .styled-p {
    font-size: 1.5rem; /* Adjust for larger unfolded display */
  }
}
